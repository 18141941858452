<template>
  <transition
    enter-class="opacity-0"
    enter-active-class="ease-out transition-medium"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-active-class="ease-out transition-medium"
    leave-to-class="opacity-0"
  >
    <div
      @keydown.esc="isOpen = false"
      v-show="showBackdrop"
      class="fixed inset-0 transition-opacity"
    >
      <div
        @click="isOpen = false"
        class="absolute inset-0 bg-black opacity-50"
        tabindex="0"
      ></div>
    </div>
  </transition>

  <div
    v-if="showNewGroupModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div
          class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          "
        >
          Nova turma
        </div>
        <div class="p-2 mt-2">
          <form>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="text"
                  required
                  :disabled="creatingNewGroup"
                  id="create-group"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="group"
                  v-model="group"
                  placeholder="Turma"
                  @blur="validateInput('group')"
                />
                <small v-if="errors.group" id="groupError" class="text-red-500">
                  {{ errors.group }}
                </small>
              </div>
            </div>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="number"
                  min="2020"
                  required
                  :disabled="creatingNewGroup"
                  id="create-account-email"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="year"
                  v-model="year"
                  placeholder="Ano"
                  @blur="validateInput('year')"
                />
                <small v-if="errors.year" id="yearError" class="text-red-500">
                  {{ errors.year }}
                </small>
              </div>
            </div>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <Multiselect
                  placeholder="Professor(a)"
                  v-model="teacher"
                  :options="teachersArray"
                  :searchable="true"
                  class="multiselect-purple"
                  :canDeselect="false"
                  @select="validateInput('teacher')"
                ></Multiselect>
                <small
                  v-if="errors.teacher"
                  id="teacherError"
                  class="text-red-500"
                >
                  {{ errors.teacher }}
                </small>
              </div>
            </div>
          </form>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button
            type="button"
            :disabled="
              this.errors.name ||
              this.errors.year ||
              this.errors.teacher ||
              creatingNewGroup
            "
            @click="onNewGroup"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            "
          >
            <svg
              v-if="creatingNewGroup"
              class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span v-if="!creatingNewGroup">Criar</span>
          </button>
          <button
            type="button"
            @click="onCloseNewTeacherModal"
            class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showEditGroupModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-4/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div
          class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          "
        >
          {{ `${groupBeingEdited.turma} - ${groupBeingEdited.ano}` }}
          <br />
          <small>{{
            `Professor(a) ${getTeacherNameByUid(groupBeingEdited.professor.id)}`
          }}</small>
        </div>
        <div class="p-2 mt-2">
          <div class="flex flex-col mb-2">
            <div class="relative text-left">
              <Multiselect
                placeholder="Aluno(a)"
                v-model="newStudent"
                :options="studentsArray"
                :searchable="true"
                :limit="10"
                class="multiselect-purple"
                :canDeselect="false"
                @select="validateInput('newStudent')"
              ></Multiselect>
              <small
                v-if="errors.newStudent"
                id="newStudentError"
                class="text-red-500"
              >
                {{ errors.newStudent }}
              </small>
            </div>
          </div>
          <div class="flex flex-col mb-2">
            <button
              type="button"
              @click="addStudentToGroup"
              class="
                px-3
                py-2
                bg-gray-800
                text-white text-xs
                font-bold
                uppercase
                rounded
                bg-indigo-600
                hover:bg-indigo-700
                focus:ring-indigo-500 focus:ring-offset-indigo-200
                transition
                ease-in
                duration-200
                font-semibold
                shadow-md
                focus:outline-none focus:ring-2 focus:ring-offset-2
              "
            >
              Adicionar aluno
            </button>
          </div>
        </div>

        <div class="p-2 mt-2">
          <div class="flex flex-col mb-2">
            <svg
              v-if="editingStudents"
              class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-12
                w-12
                text-indigo-500
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <h3
              v-if="
                !groupBeingEditedStudents ||
                (groupBeingEditedStudents.length === 0 && !editingStudents)
              "
            >
              Adicione alunos usando o campo acima
            </h3>

            <h1
              class="text-xl text-bold"
              v-if="groupBeingEditedStudents?.length > 0 && !editingStudents"
            >
              Alunos
            </h1>
            <div
              v-if="groupBeingEditedStudents?.length > 0 && !editingStudents"
              class="flex flex-col w-full overflow-y-scroll max-h-64"
            >
              <div
                v-for="student in groupBeingEditedStudents"
                :key="student.id"
                class="
                  w-full
                  border-gray-200
                  rounded-t
                  border-b
                  hover:bg-teal-100
                "
              >
                <div
                  class="
                    flex
                    w-full
                    items-center
                    p-2
                    pl-2
                    border-transparent border-l-2
                    relative
                    hover:border-teal-100
                  "
                >
                  <div class="w-6 flex flex-col items-center">
                    <div
                      class="
                        flex
                        relative
                        w-12
                        h-12
                        bg-orange-500
                        justify-center
                        items-center
                        m-1
                        mr-2
                        mt-1
                        rounded-full
                      "
                    >
                      <img
                        class="rounded-full"
                        alt="A"
                        src="@/assets/img/user_placeholder.png"
                      />
                    </div>
                  </div>
                  <div class="w-full items-center flex">
                    <div class="mx-8 -mt-1">
                      {{ student.nome }}<br />
                      <small>{{
                        "Série: " + getSeriesName(student.serie)
                      }}</small>
                      <small>{{
                        " | Ciclo: " + getCycleName(student.ciclo)
                      }}</small>
                    </div>
                  </div>
                  <div class="flex">
                    <svg
                      @click="deleteStudentFromGroup(student)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      stroke="none"
                      fill="currentColor"
                      viewBox="0 0 28 28"
                      class="text-indigo-500"
                    >
                      <path
                        d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button
            type="button"
            @click="onDeleteGroup"
            class="
              py-2
              px-4
              bg-indigo-500
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Excluir
          </button>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button
            type="button"
            @click="onHideEditGroupModal"
            class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="
      w-100
      mx-auto
      bg-white
      border-t-4
      rounded
      border-indigo-500
      dark:bg-gray-800
      shadow-md
      font-sans
      text-center
      p-4
    "
  >
    <div class="overflow-hidden">
      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h1 class="text-2xl text-left font-medium text-gray-800">Turmas</h1>
        <button
          @click="onShowNewGroupModal"
          class="
            px-3
            py-2
            bg-gray-800
            text-white text-xs
            font-bold
            uppercase
            rounded
            bg-indigo-600
            hover:bg-indigo-700
            focus:ring-indigo-500 focus:ring-offset-indigo-200
            transition
            ease-in
            duration-200
            font-semibold
            shadow-md
            focus:outline-none focus:ring-2 focus:ring-offset-2
          "
        >
          Nova turma
        </button>
      </div>

      <div class="flex flex-wrap item-center justify-end m-2 mb-6">
        <input
          type="number"
          class="
            rounded-lg
            border-transparent
            appearance-none
            border border-gray-300
            py-2
            px-4
            bg-white
            text-gray-700
            placeholder-gray-400
            shadow-sm
            text-base
            focus:outline-none
            focus:ring-2
            focus:ring-purple-600
            focus:border-transparent
          "
          min="2020"
          step="1"
          v-model="selectedYearFilter"
        />
        <button
          @click="filterGroupsByYear"
          class="
            px-3
            py-2
            bg-gray-800
            ml-2
            text-white text-xs
            font-bold
            uppercase
            rounded
            bg-indigo-600
            hover:bg-indigo-700
            focus:ring-indigo-500 focus:ring-offset-indigo-200
            transition
            ease-in
            duration-200
            font-semibold
            shadow-md
            focus:outline-none focus:ring-2 focus:ring-offset-2
          "
        >
          Filtrar por ano
        </button>
      </div>

      <GroupsTable
        :groups="groups"
        :getTeacherNameByUid="getTeacherNameByUid"
        :loading="loadingGroups"
        :editGroup="editGroup"
      />
    </div>
  </div>

  <div
    v-if="showErrorNewTeacherModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      dark:bg-gray-800
      w-64
      m-auto
      z-30
      transform
      top-0
      left-0
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <svg viewBox="0 0 24 24" class="h-12 w-12 mt-4 m-auto text-red-600">
          <path
            fill="currentColor"
            d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
          ></path>
        </svg>
        <p class="text-gray-600 dark:text-gray-100 text-md py-2 px-6">
          Erro ao cadastrar o ano
          <span class="text-gray-800 dark:text-white font-bold">
            {{ this.year }}
          </span>
          {{ errors.year ? errors.year : null }}
        </p>
        <div class="flex items-center justify-between gap-4 w-full mt-8">
          <button
            type="button"
            @click="onHideErrorNewTeacherModal"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import Firebase from "@/firebase";
import GroupsTable from "@/components/GroupsTable.vue";
import Swal from "sweetalert2";

export default {
  components: {
    GroupsTable,
  },

  setup() {
    const currentYear = new Date().getFullYear();
    const selectedYearFilter = ref(currentYear);
    const groups = ref([]);
    const loadingGroups = ref(true);

    const getGroups = async () => {
      loadingGroups.value = true;

      const groupsSnapshots = await Firebase.getDocs(
        Firebase.collections.GROUPS,
        Firebase.where("ano", "==", selectedYearFilter.value),
        Firebase.orderBy("turma")
      );
      groups.value = groupsSnapshots.docs.map((snapshot) => ({
        ...snapshot.data(),
        id: snapshot.id,
      }));
      loadingGroups.value = false;
    };

    const addLocalGroup = (newGroup) => {
      groups.value = [...groups.value, newGroup].filter(
        (group) => group.ano == selectedYearFilter.value
      );
    };

    const errors = reactive({
      name: null,
      year: null,
      teacher: null,
      newStudent: null,
    });

    const teachers = ref({});
    const teachersArray = [];
    const loadingTeachers = ref(true);

    const getTeachers = async () => {
      loadingTeachers.value = true;
      const teachersSnapshots = await Firebase.getDocs(
        "professores",
        Firebase.where("ativo", "==", true)
      );
      teachersSnapshots.docs.forEach((snapshot) => {
        const data = snapshot.data();
        teachers.value[snapshot.id] = data;
        teachersArray.push({ value: data, label: data.nome });
      });
      loadingTeachers.value = false;
    };

    const getTeacherNameByUid = (uid) => {
      return teachers.value[uid]?.nome || "Não informado";
    };

    const groupBeingEdited = ref(null);
    const setGroupBeingEdited = (group) => {
      groupBeingEdited.value = group;
    };

    const groupBeingEditedStudents = ref([]);
    const setGroupBeingEditedStudents = (students) => {
      groupBeingEditedStudents.value = students;
    };

    const newStudent = ref(null);

    const addStudentToGroupBeingEdited = async () => {
      groupBeingEditedStudents.value = [
        ...groupBeingEditedStudents.value,
        newStudent.value,
      ].sort((a, b) => {
        return a.nome.localeCompare(b.nome);
      });

      const groupBeingEditedStudentdsIds = groupBeingEditedStudents.value.map(
        (s) => s.id
      );

      await Firebase.setDoc(
        Firebase.collections.GROUPS + "/" + groupBeingEdited.value.id,
        {
          alunos: groupBeingEditedStudentdsIds,
        }
      );
    };

    const removeStudentFromGroup = async (id) => {
      groupBeingEditedStudents.value = groupBeingEditedStudents.value.filter(
        (student) => student.id !== id
      );

      const groupBeingEditedStudentdsIds = groupBeingEditedStudents.value.map(
        (s) => s.id
      );

      await Firebase.setDoc(
        Firebase.collections.GROUPS + "/" + groupBeingEdited.value.id,
        {
          alunos: groupBeingEditedStudentdsIds,
        }
      );
    };

    const studentsArray = ref([]);
    const loadingStudents = ref(false);

    const getStudents = async () => {
      loadingStudents.value = true;
      const studentsSnapshots = await Firebase.getDocs("alunos");
      studentsArray.value = studentsSnapshots.docs.map((doc) => {
        const s = {
          id: doc.id,
          ...doc.data(),
        };
        return { value: s, label: s.nome };
      });
      studentsArray.value.sort((a, b) => a.label.localeCompare(b.label));
      loadingStudents.value = false;
    };

    const seriesArray = [];
    const cyclesArray = [];

    const loadingConfig = ref(false);

    const getConfig = async () => {
      loadingConfig.value = true;
      const configSnapshot = await Firebase.getDoc("config/prod");
      const config = configSnapshot.data();

      config.series.forEach((serie) =>
        seriesArray.push({
          value: serie,
          label: serie.nome,
        })
      );

      config.ciclos.forEach((cycle) =>
        cyclesArray.push({
          value: cycle,
          label: cycle.nome,
        })
      );

      loadingConfig.value = false;
    };

    const deleteGroup = async (id) => {
      await Firebase.deleteDoc(Firebase.collections.GROUPS + "/" + id);

      groups.value = groups.value.filter((group) => group.id !== id);
    };

    return {
      newStudent,
      loadingConfig,
      getConfig,
      loadingGroups,
      loadingStudents,
      studentsArray,
      seriesArray,
      cyclesArray,
      getStudents,
      groups,
      getGroups,
      addLocalGroup,
      errors,
      teachers,
      teachersArray,
      loadingTeachers,
      getTeachers,
      getTeacherNameByUid,
      groupBeingEdited,
      setGroupBeingEdited,
      groupBeingEditedStudents,
      setGroupBeingEditedStudents,
      addStudentToGroupBeingEdited,
      removeStudentFromGroup,
      selectedYearFilter,
      deleteGroup,
    };
  },

  data() {
    return {
      showBackdrop: false,
      creatingNewGroup: false,
      showSuccessNewTeacherModal: false,
      showErrorNewTeacherModal: false,
      showNewGroupModal: false,
      showEditGroupModal: false,
      group: "",
      year: new Date().getFullYear(),
      teacher: null,
      loadingEditGroupStudents: false,
      editingStudents: false,
    };
  },

  methods: {
    getSeriesName(seriesId) {
      const s = this.seriesArray.find((serie) => serie.value.id === seriesId);
      return s?.value?.nome || "Sem série";
    },
    getCycleName(cycleId) {
      const c = this.cyclesArray.find((cycle) => cycle.value.id === cycleId);
      return c?.value?.nome || "Sem ciclo";
    },
    onShowNewGroupModal() {
      this.showNewGroupModal = true;
      this.showBackdrop = true;
    },
    onShowEditGroupModal() {
      this.showEditGroupModal = true;
      this.showBackdrop = true;
    },
    onHideEditGroupModal() {
      this.showEditGroupModal = false;
      this.showBackdrop = false;
      this.errors.newStudent = null;
    },
    onHideErrorNewTeacherModal() {
      this.creatingNewGroup = false;
      this.onCloseNewTeacherModal();
      this.showNewGroupModal = true;
      this.showBackdrop = true;
      this.showErrorNewTeacherModal = false;
    },
    onHideSuccessNewTeacherModal() {
      this.creatingNewGroup = false;
      this.onCloseNewTeacherModal();
      this.showNewGroupModal = true;
      this.showBackdrop = true;
      this.showSuccessNewTeacherModal = false;
    },
    async onDeleteGroup() {
      Swal.fire({
        title: "Você tem certeza?",
        text: `Você realmente quer remover a turma '${this.groupBeingEdited.turma} - ${this.groupBeingEdited.ano}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não, cancelar",
        confirmButtonText: "Sim, excluir",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteGroup(this.groupBeingEdited.id);
          this.onHideEditGroupModal();
        }
      });
    },
    async onNewGroup() {
      this.validateInput("group");
      this.validateInput("year");
      this.validateInput("teacher");

      if (
        !this.group ||
        !this.year ||
        !this.teacher ||
        this.errors.group ||
        this.errors.year ||
        this.errors.teacher
      ) {
        return;
      }

      this.creatingNewGroup = true;

      const newGroup = {
        turma: this.group,
        ano: this.year,
        professor: Firebase.doc(
          `${Firebase.collections.TEACHERS}/${this.teacher.uid}`
        ),
      };

      try {
        await Firebase.addDoc(Firebase.collections.GROUPS, newGroup);
        this.addLocalGroup(newGroup);
        this.showBackdrop = true;
        this.showNewGroupModal = false;
        Swal.fire(
          "Sucesso",
          `Turma '${newGroup.turma} - ${newGroup.ano}' criada com sucesso!`,
          "success"
        ).finally(() => {
          this.onHideSuccessNewTeacherModal();
        });
      } catch (e) {
        console.error(e);
        this.showNewGroupModal = false;
        this.showErrorNewTeacherModal = true;
      }
    },
    validateInput(formName) {
      if (formName === "group") {
        if (!this.group || this.group.length < 5) {
          this.errors.group = "Nome para turma deve ter no mín. 5 caracteres";
        } else {
          this.errors.group = false;
        }
      }

      if (formName === "year") {
        if (!this.year || this.year < 2020) {
          this.errors.year =
            "Ano inválido. Escolha um ano maior ou igual a 2020";
        } else {
          this.errors.year = false;
        }
      }

      if (formName === "teacher") {
        if (!this.teacher) {
          this.errors.teacher = "Selecione um(a) professor(a)";
        } else {
          this.errors.teacher = false;
        }
      }

      if (formName === "newStudent") {
        if (!this.newStudent) {
          this.errors.newStudent = "Você deve selecionar um aluno";
        } else {
          this.errors.newStudent = null;
        }
      }
    },
    onCloseNewTeacherModal() {
      this.showNewGroupModal = false;
      this.showBackdrop = false;
      this.group = "";
      this.email = "";
      this.teacher = "";
      this.errors.group = "";
      this.errors.email = "";
      this.errors.teacher = "";
    },
    async editGroup(group) {
      this.setGroupBeingEdited(group);
      this.loadingEditGroupStudents = true;

      const promises = [];

      group.alunos?.forEach((aluno) => {
        promises.push(
          Firebase.getDoc(Firebase.collections.STUDENTS + "/" + aluno)
        );
      });

      const studentsSnapshots = await Promise.all(promises);

      const students = studentsSnapshots.map((snapshot) => ({
        id: snapshot.id,
        ...snapshot.data(),
      }));

      this.setGroupBeingEditedStudents(students);

      this.loadingEditGroupStudents = false;

      this.onShowEditGroupModal();
    },
    async addStudentToGroup() {
      this.validateInput("newStudent");

      if (this.errors.newStudent) {
        return;
      }

      this.editingStudents = true;
      await this.addStudentToGroupBeingEdited();
      this.editingStudents = false;
    },

    async deleteStudentFromGroup(student) {
      this.editingStudents = true;

      Swal.fire({
        title: "Você tem certeza?",
        text: `Você realmente quer remover o aluno '${student.nome}' da turma?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não, cancelar",
        confirmButtonText: "Sim, excluir",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.removeStudentFromGroup(student.id);
          }
        })
        .finally(() => {
          this.editingStudents = false;
        });
    },
    async filterGroupsByYear() {
      this.loadingGroups = true;
      await this.getGroups();
      this.loadingGroups = false;
    },
  },

  mounted() {
    this.getTeachers().then(() => {
      this.getGroups();
    });
    this.getConfig();
    this.getStudents();
  },
};
</script>

<style scoped>
.multiselect-purple {
  --ms-ring-width: 2px;
  --ms-ring-color: #8c54ec;
  --ms-option-bg-pointed: #ffffff;
  --ms-option-bg-selected: #ccb0fa;
  --ms-option-bg-disabled: #ffffff;
  --ms-option-bg-selected-pointed: #b48cf4;
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-color-selected: #ffffff;
  --ms-option-color-disabled: #d1d5db;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-option-color-selected-disabled: #d1fae5;
}

.modal {
  position: fixed !important;
  height: auto !important;
  top: 100px !important;
  left: 0 !important;
  right: 0 !important;
}
</style>
