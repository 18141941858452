<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Turma
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Ano
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Professor(a)
                </th>
              </tr>
            </thead>

            <tbody v-if="groups.length === 0 && !loading">
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  Não existem turmas cadastradas
                </td>
              </tr>
            </tbody>

            <tbody v-if="loading">
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  <svg
                    class="relative left-1/2 animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </td>
              </tr>
            </tbody>

            <tbody v-if="!loading" class="bg-white divide-y divide-gray-200">
              <tr v-for="group in groups" :key="group.id">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        class="h-10 w-10 rounded-full"
                        src="@/assets/img/user_placeholder.png"
                      />
                    </div>
                    <div class="ml-4">
                      <div class="text-md font-medium text-gray-900">
                        {{ group.turma }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-md text-gray-500">
                    {{ group.ano }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-md text-gray-800">
                    {{ getTeacherName(group.professor) }}
                  </div>
                </td>
                <td
                  class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium"
                >
                  <a
                    class="cursor-pointer text-indigo-600 hover:text-indigo-900"
                    @click="editGroup(group)"
                    >Gerenciar</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["groups", "getTeacherNameByUid", "loading", "editGroup"],
  methods: {
    getTeacherName(teacher) {
      return this.getTeacherNameByUid(teacher.id);
    },
    showEditGroupModal(group) {
      return this.editGroup(group);
    },
  },
};
</script>
